import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Image from '../../components/image'
import config from "../../../data/config.js";

const BioWarpper = styled.div`
  margin: 0px;
  width:100%;
  background: #f2efb6;
  text-align: center;
  border-radius:8px;
  padding:16px;
  box-sizing: border-box;
`
const BioImage = styled.div`
  height:64px;
  width:64px;
  margin: auto;
`
const Social = styled.div`
  height:32px;
  width:32px;
  margin: auto;
`

const Bio = () => (
<BioWarpper>
<BioImage>
<Image filename="profile-pic.jpg">
</Image></BioImage>
<p>{config.author}</p>
<p>{config.blogAuthorDescription}</p>
<Social>
<a href="https://twitter.com/intent/follow?screen_name=1042limit" target="_blank">
  <Image filename="twitter48.png"></Image>
  </a>
  </Social>
</BioWarpper>
)

export default Bio
