import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Header from "./header"
import "./layout.css"
import styled from 'styled-components'
import Footer from "./Footer"
import Sidebar from "./Sidebar"
import NavBar from "./NavBar"

const Main = styled.div`
padding: 0px 1.0875rem 1.45rem;
max-width :1080px;
box-sizing: border-box;
paddingTop: 0;
`
const Wrapper = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: minmax(320px, 2fr) minmax(320px, 1fr);
  margin-top: 96px;
  box-sizing: border-box;
  @media(max-width: 768px){
    grid-template-columns: 1fr;
  }
`
const Side = styled.div`
`
const Container = styled.div`
max-width :1080px;
box-sizing: border-box;
margin:auto;
`

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
        <NavBar/>
        <Container>
        <Wrapper>
        <Main>
          <main>{children}</main>
        </Main>
        <Side>
          <Sidebar/>
        </Side>
        </Wrapper>
        </Container>
        <Footer></Footer>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
