import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const FooterDiv = styled.footer`
    width: 100%;
    height: 32px;
    margin:0px;
    padding:8px;
    background: #074684;
    text-align: center;
    box-sizing: border-box;
    color:white;
    a{
      color:white;
    }
`


const Footer = () => (
    <FooterDiv>
    © {new Date().getFullYear()}, 
    Corydoras with 
    <a href="https://www.gatsbyjs.org">Gatsby</a>
    </FooterDiv>
)

export default Footer
