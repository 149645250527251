import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Image from '../../components/image'

const NavBarWrapper = styled.div`
  margin: 0px;
  background: #0ea5c6;
  position: fixed;
  top: 56px;
  left: 0;
  width: 100%;
  height: 32px;
  text-align: center;
  z-index:3;
  padding:4px;
  box-sizing: border-box;
`
const StyledLink = styled(Link)`
  color: #000;
  textdecoration: none;
`
const Icon = styled.div`
display:inline-block;
width:24px;
height:24px;
vartical-align: middle;
`
const IconTitle = styled.h3`
display:inline-block;
font-size: 1rem;
margin:0 8px 0 8px;
height:24px;
`
const NavBar = () => (
  <NavBarWrapper>
  <Icon>
      <Image filename="gatsby-icon.png"></Image>
  </Icon>
  <StyledLink to="/gatsby/"><IconTitle>GatsbyJS</IconTitle></StyledLink>
  <Icon>
      <Image filename="python-logo.png"></Image>
  </Icon>
  <StyledLink to="/python/"><IconTitle>Python</IconTitle></StyledLink>
  <Icon>
      <Image filename="book32.png"></Image>
  </Icon>
  <StyledLink to="/book/"><IconTitle>Books</IconTitle></StyledLink>
  </NavBarWrapper>
)

export default NavBar
