import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const HeaderWrapper = styled.div`
`
const SiteTitle = styled.h1`
text-align:center;
line-height:32px;
`
const Headline = styled.div`
    vertical-align:middle;
    position: fixed;
    margin:0px;
    top: 0;
    left: 0;
    width: 100%;
    height: 56px;
    background: #074684;
    text-align: center;
    z-index:3;

`

const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
`

const Header = () => (
  <HeaderWrapper>
    <Headline>
      <SiteTitle>
        <StyledLink to="/">Corylog</StyledLink>
      </SiteTitle>
    </Headline>
  </HeaderWrapper>
)

export default Header
