import React from "react";
import AdSense from 'react-adsense'

const Ad = () => (
  <div>
      <AdSense.Google
        client="ca-pub-4754846686872982"
        slot="4052168141"
        format="rectangle"
        />
    </div>
  )
export default Ad;
