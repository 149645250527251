const config = {
  blogAuthorDescription:"コリといいます。奈良県でサラリーマンをしています。GatsbyJSでサイトを作るのが趣味です。",
  blogAuthorAvatarUrl:"https://www.corylog.com/img/logo.png",
  blogImageUrl:"https://www.corylog.com/img/logo.png",
  blogUrl:"https://www.corylog.com",
  blogAuthorFacebookUrl:"",
  blogAuthorTwitterUrl:"https://twitter.com/1042limit",
  siteTitle: "コリログ", // Site title.
  author:　"コリ",
  description:"青コリの技術ブログ 主にフロントエンドについて書いています。",
  siteTitleShort: "コリログ", // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: "コリログ", // Alternative site title for SEO.
  siteLogo: "/logos/logo-1024.png", // Logo used for SEO and manifest.
  siteUrl: "https://corylog.com", // Domain of your website without pathPrefix.
  siteDescription: "青コリの技術ブログ 主にフロントエンドについて書いています。", // Website description used for RSS feeds/meta description tag.
  siteRss: "/rss.xml", // Path to the RSS file.
  googleAnalyticsID: "UA-72545034-1", // GA tracking ID.
  postDefaultCategoryID: "Coffe", // Default category for posts.
  dateFromFormat: "YYYY-MM-DD", // Date format used in the frontmatter.
  dateFormat: "DD/MM/YYYY", // Date format for display.
  userName: "aocory", // Username to display in the author segment.
  userTwitter: "@1042limit", // Optionally renders "Follow Me" in the UserInfo segment.
  userLocation: "Nara", // User location to display in the author segment.
  userDescription:
    "奈良県で会社員しながら趣味の個人開発でフロントエンドを勉強しています。", 
  copyright: "Copyright © 2019. コリ", // Copyright string for the footer of the website and RSS feed.
  themeColor: "#c62828", // Used for setting manifest and progress theme colors.
  backgroundColor: "#e0e0e0" // Used for setting manifest background color.
}
module.exports = config;
