import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Bio from "../../components/Bio"
import Ad from "../../components/Ad"

const Adwarp = styled.div`
    width: 100%;
`


const StyledLink = styled(Link)`
  color: white;
  textdecoration: none;
`

const Sidebar = () => (
<div>
<Bio></Bio>
<Adwarp>
<Ad></Ad>
</Adwarp>
</div>
)

export default Sidebar
